import React from "react"

import Layout from "../components/Layout"
import PageTitleSmall from "../components/PageTitleSmall"
import SEO from "../components/seo"
import PageContent from "../components/PageContent"

const Datenschutz = () => (
  <Layout>
    <SEO title="Datenschutzerklärung" />
    <PageTitleSmall title="Datenschutzerklärung" />
    <PageContent alignment="left">
      <p>
        Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir
        verarbeiten Ihre Daten daher ausschließlich auf Grundlage der
        gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
        Datenschutzinformationen informieren wir Sie über die wichtigsten
        Aspekte der Datenverarbeitung im Rahmen unserer Website. Trotz
        sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die
        Inhalte externer Links. Für den Inhalt sind ausschließlich deren
        Betreiber verantwortlich.
      </p>
      <br />
      <h2>Urheberrechte</h2>
      <p>
        Der Inhalt der Website ist urheberrechtlich geschützt. Die
        Bereitstellung von Inhalten und Bildmaterial dieser Webseiten auf
        anderen Webseiten ist nur mit ausdrücklicher Genehmigung gestattet. Die
        enthaltenen Angaben werden nach bestem Wissen erstellt und mit großer
        Sorgfalt auf ihre Richtigkeit geprüft. Trotzdem sind inhaltliche und
        sachliche Fehler nicht vollständig auszuschließen. Bosphorus Restaurant
        übernimmt keine Garantie und Haftung für die Richtigkeit, Aktualität und
        Vollständigkeit der Angaben. Alle Angaben sind ohne Gewähr, das gilt
        auch für Links zu anderen URLs, die auf der Website eigebunden sind.
        Alle Preise sind in Euro angegeben, Preisänderungen und Satzfehler
        vorbehalten.
      </p>
      <br />
      <h2>Kontakt mit uns.</h2>
      <p>
        Wenn Sie per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen
        Daten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir
        nicht ohne Ihre Einwilligung weiter.
      </p>
      <br />
      <h2>Cookies</h2>
      <p>
        Wir möchten darauf hinweisen, dass unsere Webseite{" "}
        <u>
          <b>keine</b>
        </u>{" "}
        Cookies verwendet.
      </p>
      <br />
      <h2>Facebook</h2>
      <p>
        Verwendung von Facebook-Plugins Wir haben in unsere Internetpräsenz
        sogenannte Plugins des sozialen Netzwerkes facebook.com (nachfolgend
        „Facebook”) integriert. Facebook ist ein Unternehmen der Facebook Inc.,
        1601 S. California Ave, Palo Alto, CA 94304, USA. Eine Auflistung und
        das Aussehen dieser Plugins von Facebook kann unter der nachfolgenden
        Internetadresse eingesehen werden:
        https://developers.facebook.com/docs/plugins/ Bei jedem Aufruf einer
        Webseite unserer Internetpräsenz, die mit einem solchen Plugin versehen
        ist, veranlasst das Plugin, dass der von Ihnen verwendete Browser die
        optische Darstellung des Plugins vom Facebook-Server lädt und darstellt.
        Durch die Einbindung der Plugins erhält Facebook die Information, dass
        Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat,
        auch wenn Sie kein Facebook-Benutzerkonto besitzen oder gerade nicht bei
        Facebook eingeloggt sind. Diese Information (einschließlich Ihrer
        IP-Adresse) wird von Ihrem Browser direkt an einen Server von Facebook
        in den USA übermittelt und dort gespeichert. Wenn Sie Mitglied bei
        Facebook und während des Besuchs unserer Internetpräsenz bei Facebook
        eingeloggt sind, erkennt Facebook durch die von dem Plugin gesendete
        Information, welche bestimmte Webseite unserer Internetpräsenz Sie
        gerade besuchen und weist dies Ihrem persönlichen Benutzerkonto auf
        Facebook zu, unabhängig davon, ob Sie eines der Plugins betätigen.
        Betätigen Sie nun eines der Plugins, beispielsweise durch Anklicken des
        „Gefällt mir”-Buttons oder durch die Abgabe eines Kommentares, wird dies
        an Ihr persönliches Benutzerkonto auf Facebook gesendet und dort
        gespeichert. Um zu verhindern, dass Facebook die gesammelten Daten Ihrem
        Benutzerkonto auf Facebook zuordnet, müssen Sie sich bei Facebook
        ausloggen, bevor Sie unsere Internetpräsenz besuchen. Um die
        Datenerhebung und -weitergabe Ihrer Besucherdaten durch Facebook-Plugins
        für die Zukunft zu blocken, können Sie für einige Internetbrowser unter
        nachfolgendem Link (externe Seite) ein Browser-Add-On von „ADGUARD”
        beziehen. Bitte löschen Sie das Browser-Add-On nicht, solange Sie die
        Facebook-Plugins blocken möchten: https://adguard.com/de/welcome.html
        Unter der nachstehenden Internetadresse finden Sie die
        Datenschutzhinweise von Facebook mit näheren Informationen zur Erhebung
        und Nutzung von Daten durch Facebook, zu Ihren diesbezüglichen Rechten
        sowie zu den Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre:
        http://www.facebook.com/policy.php
      </p>
      <br />
      <h2>Ihre Rechte</h2>
      <p>
        Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich
        die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung,
        Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben,
        dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt
        oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt
        worden sind, können Sie sich bei uns{" "}
        <a href="mailto:service@bosphorus-restaurant.at">
          service@bosphorus-restaurant.at
        </a>{" "}
        oder der Datenschutzbehörde beschweren.
      </p>
    </PageContent>
  </Layout>
)

export default Datenschutz
